import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import DataProtectionMeasures from "../components/data-protection-measures"
import FeatureColumn from "../components/feature-column"
import Testimonials from "../components/testimonials"
import Demo from "../components/demo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faIndustry,
  faChartLine,
  faEuroSign,
  faBolt,
  faTractor,
  faHome,
  faCloud,
  faMountain,
  faArrowRight,
  faHardHat,
} from "@fortawesome/free-solid-svg-icons"

const DroneMapping = () => (
  <Layout>
    <SEO
      title="Automated Blurring for Drone Mapping | Celantur"
      description="High-quality and scalable image anonymization solution to automatically blur faces, bodies, license plates on drone images and videos"
    />

    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-50 bg-cover text-center"
      style={{
        background: "url(/images/drone-mapping.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1
          className="display-2 font-weight-bold text-white"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          Automated <span className="text-success">Blurring</span>
          <br />
          for Drone Mapping
        </h1>
        <p
          className="lead mb-6 mb-md-8 text-gray-200"
          style={{ textShadow: "2px 2px 4px #000000" }}
        >
          High-quality and scalable image anonymization solution.
          <br /> Automatically blur faces, bodies, license plates on images and
          videos
        </p>
        <a
          href="https://app.celantur.com/"
          className="btn btn-success lift event-start-demo"
        >
          Start Demo <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
        </a>
        <Link
          to="/contact/"
          className="btn btn-outline-light ml-3 lift event-contact"
        >
          Contact Us
        </Link>
      </div>
    </section>

    <section className="pt-5 pb-8">
      <Customers />
    </section>

    <section className="pt-8">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 col-lg-5 mt-5 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0 mb-5">
            <h2 className="font-weight-bold">
              <span className="text-primary">Blur personal data</span>
              <br /> automatically.
            </h2>

            <p className="font-size-lg text-gray-700 mb-6">
              Our solution can be configured to blur several object types, to
              comply with data protection laws.
            </p>

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#4285f4" }}
                    />
                  </div>

                  <p>Faces</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#4285f4" }}
                    />
                  </div>

                  <p className="mb-lg-0">Bodies</p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#4285f4" }}
                    />
                  </span>

                  <p>License Plates</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary-soft mr-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#4285f4" }}
                    />
                  </div>

                  <p className="mb-0">Vehicles</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-5 mb-5">
            <h2 className="font-weight-bold">
              <span className="text-primary">For images and videos</span>
              <br /> of any inspection.
            </h2>
            <p className="font-size-lg text-gray-700 mb-6">
              Anonymize single and panorama images or videos of any inspection
              type.
            </p>

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faIndustry}
                      style={{ color: "#4285f4" }}
                    />
                  </div>
                  <p>Industrial</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faBolt}
                      style={{ color: "#4285f4" }}
                    />
                  </div>
                  <p className="mb-lg-0">Utility Mapping</p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faTractor}
                      style={{ color: "#4285f4" }}
                    />
                  </span>
                  <p>Agricultural</p>
                </div>
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faHardHat}
                      style={{ color: "#4285f4" }}
                    />
                  </span>
                  <p>Construction Site</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5 py-md-8">
      <div className="container">
        <div className="row align-items-center">
          <div
            className="col-12 col-md-6 col-lg-5 aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 className="font-weight-bold">
              Our software runs <br />
              <span className="text-success">where you need it to</span>.
            </h2>

            <p className="font-size-lg text-gray-700">
              Different projects have different requirements. Choose a platform
              to enable maximum scalability, security, speed and connectivity.
              <br />
              Run it where you need it.
            </p>
          </div>
          <div className="col-12 col-md-6 col-lg-6 offset-lg-1">
            <div
              className="card card-border border-success shadow-lg mb-5 aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="card-body">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="mr-auto">
                      <p className="font-weight-bold mb-1">Cloud</p>
                      <p className="font-size-sm text-muted mb-0">
                        Full-service, easy and scalable.
                      </p>
                    </div>
                    <div className="badge badge-rounded-circle badge-success-soft ml-4">
                      <FontAwesomeIcon
                        icon={faCloud}
                        style={{ color: "#42ba96" }}
                      />
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="mr-auto">
                      <p className="font-weight-bold mb-1">On-the-edge</p>
                      <p className="font-size-sm text-muted mb-0">
                        In-house and connected to the internet.
                      </p>
                    </div>
                    <div className="badge badge-rounded-circle badge-success-soft ml-4">
                      <FontAwesomeIcon
                        icon={faMountain}
                        style={{ color: "#42ba96" }}
                      />
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="mr-auto">
                      <p className="font-weight-bold mb-1">
                        Offline On-premise
                      </p>
                      <p className="font-size-sm text-muted mb-0">
                        Runs at your, or your customer's in-house
                        infrastructure.
                      </p>
                    </div>
                    <div className="badge badge-rounded-circle badge-success-soft ml-4">
                      <FontAwesomeIcon
                        icon={faHome}
                        style={{ color: "#42ba96" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container my-5 text-center">
      <Link
        to="/contact/"
        role="button"
        className="btn btn-lg btn-success my-2 event-contact"
      >
        Request Demo
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>

    <DataProtectionMeasures />

    <section className="py-8 bg-gray-200">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
            <h2 className="font-weight-bold">Why Celantur?</h2>
          </div>
        </div>
        <div className="row">
          <FeatureColumn headline="Flexible" icon={faCheck}>
            Provide imagery via cloud upload, access our services via API or run
            it on-premise.
          </FeatureColumn>
          <FeatureColumn
            headline="Scalable &amp; AI-powered"
            icon={faChartLine}
          >
            Get large projects done with ease. Our machine learning models run
            in the cloud and scale with your needs.
          </FeatureColumn>
          <FeatureColumn headline="Cost Efficient" icon={faEuroSign}>
            Automated anonymization is faster than human labor. The more you
            anonymize, the less you pay.
          </FeatureColumn>
        </div>
      </div>
    </section>

    <Testimonials all={true} />

    <Demo />

    <section className="py-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">Frequently Asked Questions</h2>
            <p className="font-size-lg text-muted mb-7 mb-md-9">
              Let us help answer the most common questions you might have
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h3>Which Drone Systems do you support?</h3>
            <p className="text-muted mb-6 mb-md-8">
              We can anonymize nearly{" "}
              <mark>all kinds of image and video data</mark>, independently from
              the drone type.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <h3>How long does anonymization usually take?</h3>
            <p className="text-muted mb-6 mb-md-8">
              Our cloud platform is capable of anonymizing around{" "}
              <mark>200.000 panoramas* in 24 hours</mark>.<br /> Processing time
              may vary depending on several factors (resolution, total amount of
              objects to anonymize). Additional time for data handling and
              transfer needs to be considered as well.
              <br />
              <small>* panorama images with a resolution of 32 MP</small>
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="container my-5 text-center">
      {" "}
      {/* CTA */}
      <Link
        to="/contact/"
        role="button"
        className="btn btn-lg btn-success my-2 event-contact"
      >
        Contact Us
        <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
      </Link>
    </section>
  </Layout>
)

export default DroneMapping
